import { useRouter } from 'next/router'
import React from 'react'

import { UIButton } from '@/components/ui'
import { Link } from '@heroui/link'

const Custom404 = () => {
  const router = useRouter()

  const goHome = () => {
    router.push('/')
  }

  return (
    <main className="grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-primary">404</p>
        <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-default-foreground sm:!text-7xl">
          Page not found
        </h1>
        <p className="mt-6 text-pretty text-lg font-medium text-foreground-500 sm:text-xl/8">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <UIButton color="primary" onClick={goHome}>
            Go back home
          </UIButton>
          <Link
            href="mailto:support@docsum.ai"
            target="_blank"
            color="primary"
            showAnchorIcon
          >
            Contact support
          </Link>
        </div>
      </div>
    </main>
  )
}

export default Custom404
